.scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
  }
  
  .scroll-button {
    border-radius: 50%;
    padding: 10px;
    font-size: 20px;
  }
  