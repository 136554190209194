.about-page {
  background-color: #f8f9fa;
  padding: 60px 15px;
}

.about-title {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 20px;
  font-weight: bold;
}

.about-description {
  font-size: 1.2rem;
  color: #555;
  line-height: 1.8;
  margin-bottom: 20px;
}

.about-encourage {
  font-size: 1.1rem;
  color: #444;
  line-height: 1.8;
  margin-top: 20px;
}

.about-encourage strong {
  color: #007bff;
}

.about-card {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.about-card .btn-primary {
  background-color: #007bff;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.about-card .btn-primary:hover {
  background-color: #0056b3;
}

.contact-info {
  margin-top: 20px;
}

.contact-info span,
.contact-info a {
  font-size: 1.2rem;
  color: #333;
}

.contact-info a:hover {
  color: #0056b3;
  text-decoration: underline;
}

.icon {
  color: #007bff;
  font-size: 1.5rem;
  margin-right: 10px;
}

.text-sm {
  font-size: 0.9rem;
  color: #888;
}

.text-lg {
  font-size: 1.2rem;
  color: #222;
  font-weight: bold;
}

.contact-info .icon {
  color: #007bff;
  font-size: 1.5rem;
  transition: transform 0.3s ease, color 0.3s ease;
}

.contact-info span,
.contact-info a {
  font-size: 1.2rem;
  color: #333;
  transition: color 0.3s ease;
}

.contact-info span:hover,
.contact-info a:hover {
  color: #0056b3; /* Đổi màu khi hover */
}

.contact-info .icon:hover {
  color: #0056b3; /* Đổi màu icon */
  transform: scale(1.2); /* Phóng to icon */
}
