/* Global Styles */
.find-password-card {
    background: #ffffff; 
    border-radius: 20px; 
    padding: 40px;
    margin-top: 8rem;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
}

.find-password-btn {
    background-color: #007bff;
    border: none;
    color: #fff; 
    border-radius: 50px; 

    font-size: 18px; 
    font-weight: 400; 
    transition: background-color 0.3s ease, box-shadow 0.3s ease; 
}

.find-password-btn:hover {
    background-color: #0056b3; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
}

.find-password-btn:disabled {
    background-color: #6c757d; 
    cursor: not-allowed;
    box-shadow: none; 
}