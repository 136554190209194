/* footer.css */

.footer-section {
    background-color: #343a40;  
    padding: 40px 0;
    font-family: 'Poppins', sans-serif;
}

/* Styling for headings */
.footer-section h5 {
    color: #f8f9fa;
    font-weight: 600;
    margin-bottom: 20px;
}

/* About section styling */
.about-section {
    color: #f8f9fa;
    padding-right: 20px;
}

/* Contact section styling */
.contact-section {
    background-color: #495057; 
    border: 1px solid #6c757d;
    border-radius: 10px;
    padding: 15px 20px; 
    display: inline-block; /* Đảm bảo khung chỉ bao quanh nội dung */
    float: right; /* Đẩy phần contact sát bên phải */
    max-width: 100%; 
}

.contact-list {
    list-style: none;
    padding-left: 0;
    margin: 0; 
}

.contact-list li {
    margin-bottom: 10px; 
}

.contact-icon {
    color: #f8f9fa;
    font-size: 20px;
    margin-right: 10px;
}

.contact-list a {
    color: #f8f9fa;
    font-size: 16px;
    text-decoration: none;
    user-select: text;
}

.contact-list a:hover {
    color: #007bff;
}

/* Footer bottom section styling */
.footer-bottom {
    background-color: #23272b;
    color: #f8f9fa;
    font-size: 14px;
    padding: 10px;
    
}

.footer-bottom a {
    color: #f8f9fa;
    font-weight: 500;
}

.footer-bottom a:hover {
    color: #007bff;
}


.lien-he {
    user-select: text;
}