/* homePage.css */
.homepage-container {
    padding: 30px 0;
}


.homepage-title {
    font-size: 2.5rem;
    color: #007bff;
    font-weight: bold;
    line-height: 1.2;
}

.homepage-hero {
    background-color: #e3ebf7;
    padding: 30px;
    border-radius: 15px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.homepage-image {
    transition: transform 0.3s ease;
}

.homepage-image:hover {
    transform: scale(1.05);
}

.homepage-card {
    background-color: #ffffff;
    border: none;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
}

.homepage-card:hover {
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
}

.homepage-section-title {
    font-size: 2rem;
    color: #343a40;
    font-weight: bold;
    margin-bottom: 20px;
}

.homepage-text {
    font-size: 1.1rem;
    color: #6c757d;
    line-height: 1.6;
}

.homepage-list {
    list-style-type: none;
    padding-left: 0;
    font-size: 1.1rem;
}

.homepage-list li {
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
}

.homepage-list li:last-child {
    border-bottom: none;
}
