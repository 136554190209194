
.question-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 3px; 
    margin-top: 20px;
    justify-content: center;
}

/* Ô vuông đại diện cho câu hỏi */
.question-grid .question-button {
    width: 30px; 
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    font-size: 1rem;
    color: white;
    padding: 0;
    position: relative;
    cursor: pointer;
    transition: transform 0.2s ease, background-color 0.3s ease;
}


.question-grid .question-button:hover {
    transform: scale(1.1); 
    box-shadow: 0 0 15px rgba(219, 163, 7, 0.8);
}



.question-grid .question-button.answered-not-current {
    background-color: #2fcaf5;
    border: 2px solid #1212e6; 
}


/* Màu sắc cho ô chưa trả lời */
.question-grid .question-button.not-answered {
    background-color: #f0db66; 
    color: black;
}


/* Màu sắc cho ô hiện tại */
.question-grid .question-button.current {
    
    border: 2px solid #04af4e; 
    color: #04af4e;
    box-shadow: 0 0 15px #20e441cc;
    transform: scale(1.1);
}

/* Biểu tượng cờ */
.question-grid .flag-icon {
    position: absolute;
    top: -5px;
    right: -5px;
    color: red;
    font-size: 1.2rem;
}

/* Hiển thị tổng thể của card câu hỏi */
.question-card {
    border: none;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
    background-color: #f8f9fa;
}

.fixed-height-card {
    height: 500px;
    overflow-y: unset;
}

.question-card .card-title {
    font-weight: bold;
    font-size: 1.5rem;
}

.question-card .card-text {
    font-size: 1.1rem;
}

/* Nút hành động */
.btn-primary {
    background-color: #007bff;
    border-color: #007bff;
    transition: background-color 0.3s ease;
}

.btn-primary:hover {
    background-color: #0056b3;
}


/* Correct answers */
.question-grid .question-button.correct {
    background-color: #4caf50; /* Green */
    color: white;
}

/* Incorrect answers */
.question-grid .question-button.incorrect {
    background-color: #f44336; /* Red */
    color: white;
}


