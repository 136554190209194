/* Custom styling for the exam component */
.exam-container {
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
}

.time-left {
    font-size: 1.5rem;
    color: #007bff;
}

.time-progress-bar {
    height: 20px;
    border-radius: 10px;
}

.question-card {
    border: none;
    background: #f8f9fa;
}

.submit-test-btn {
    padding: 10px 30px;
    font-size: 1.2rem;
}

/* Customize hover and focus effects */
.submit-test-btn:hover {
    background-color: #28a745;
    border-color: #28a745;
    box-shadow: 0px 0px 15px rgba(50, 205, 50, 0.8);
    /* Green glow effect */
}

.text-center p {
    font-size: 1.1rem;
    margin-bottom: 10px;
}

/* CSS cho phần thông báo chúc mừng hoặc thất bại */
.congratulations-message {
    font-size: 1.5rem;
    color: green;
    font-weight: bold;
    margin-top: 10px;
    animation: glow 2s infinite;
}

.failed-message {
    font-size: 1rem;
    color: red;
    font-weight: bold;
    margin-top: 10px;
}

@keyframes glow {
    0% { text-shadow: 0 0 5px #0f0, 0 0 10px #0f0, 0 0 15px #0f0, 0 0 20px #00ff00; }
    100% { text-shadow: 0 0 20px #0f0, 0 0 30px #0f0, 0 0 40px #0f0, 0 0 50px #00ff00; }
}
