* {
    user-select: none; /* Ngăn việc chọn văn bản */
        /* Ngăn hiển thị đường viền khi focus */
  }

  input, textarea, button {
    user-select: text;
    
  }

  .footer .lien-he {
    user-select: text;
  }