.register-container {
  margin-top: 10rem;
  margin-bottom: 50px;
}

.register-card {
  background: #f8f9fa;
  border-radius: 15px;
  transition: box-shadow 0.3s ease;
}

.register-card:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Thêm hiệu ứng shadow khi hover */
}

.register-title {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  color: #343a40;
}

.register-btn {
  background-color: #007bff;
  border: none;
  padding: 10px;
  font-size: 18px;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.register-btn:hover {
  background-color: #0056b3;
  transform: scale(1.05); /* Tăng kích thước nút nhẹ khi hover */
  box-shadow: 0 4px 8px rgba(0, 91, 179, 0.3); /* Hiệu ứng nổi */
}

input.form-control {
  height: 45px;
  padding-left: 15px;
  font-size: 16px;
  border: 1px solid #ced4da;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

input.form-control:focus {
  border-color: #007bff;
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.3); /* Hiệu ứng sáng khi focus */
}

input.form-control:hover {
  border-color: #0056b3; /* Đổi màu viền khi hover */
  box-shadow: 0 0 6px rgba(0, 91, 179, 0.3); /* Hiệu ứng sáng nhẹ khi hover */
}

.confirmation-modal .modal-content {
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.confirmation-modal .modal-header {
  padding-top: 20px;
}

.confirmation-modal .modal-title {
  font-weight: 600;
  color: #333;
}

.confirmation-modal .modal-body {
  padding: 30px 40px;
}

.confirmation-modal .email-highlight {
  font-weight: 600;
  color: #007bff;
  font-size: 1.1em;
}

.confirmation-modal .btn-primary {
  border-radius: 25px;
  font-weight: 500;
  transition: all 0.3s ease;
}

.confirmation-modal .btn-primary:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 123, 255, 0.3);
}

.confirmation-modal .btn-primary:focus {
  box-shadow: 0 0 10px rgba(0, 123, 255, 0.5); /* Thêm hiệu ứng sáng khi focus */
}
