.login-card {
    background: #ffffff; 
    border-radius: 20px; 
    padding: 40px;
    margin-top: 8rem; 
    transition: transform 0.4s ease, box-shadow 0.4s ease; 
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1); 
}


.login-title {
    font-family: 'Roboto', sans-serif; 
    font-weight: 700;
    color: #333; 
    font-size: 2rem;
    text-align: center;
    margin-bottom: 20px; 
    animation: fadeInDown 0.7s ease; 
}

@keyframes fadeInDown {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.login-btn {
    background-color: #28a745; 
    border: none;
    padding: 12px;
    font-size: 18px;
    width: 100%;
    border-radius: 50px;
    color: #fff;
    font-weight: bold;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.login-btn:hover {
    background-color: #218838;
    transform: translateY(-3px); 
}

input.form-control {
    height: 50px;
    padding-left: 20px;
    font-size: 16px;
    border: 1px solid #ced4da;
    border-radius: 10px;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

input.form-control:focus {
    border-color: #28a745;
    box-shadow: 0 0 5px rgba(40, 167, 69, 0.5); 
}


.modal-button {
    padding: 12px 24px;
    border-radius: 12px;
    font-weight: 500;
    transition: all 0.2s ease;
    border: none;
}

.modal-button:not(:disabled):hover {
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.modal-button:disabled {
    background-color: #e2e8f0;
    color: #718096;
    cursor: not-allowed;
}

.modal-button-secondary {
    padding: 12px 24px;
    border-radius: 12px;
    font-weight: 500;
    background-color: #f7fafc;
    color: #4a5568;
    border: 1px solid #e2e8f0;
    transition: all 0.2s ease;
}

.modal-button-secondary:hover {
    background-color: #edf2f7;
    color: #2d3748;
}

.countdown {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

/* Additional hover effects */
.modal-button:not(:disabled):active {
    transform: translateY(0);
}

/* Modal Header custom styling */
.modal-header {
    padding-bottom: 0;
}

.modal-header .close {
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    opacity: 0.5;
    transition: opacity 0.2s ease;
}

.modal-header .close:hover {
    opacity: 1;
}