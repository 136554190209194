/* Countdown clock styles */
.countdown-clock {

  color: #ffffff;
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 15px rgba(0, 123, 255, 0.5);
}

.time {
  font-weight: bold;
}

.countdown-clock:hover {
  box-shadow: 0px 0px 20px rgba(255, 99, 132, 0.8), 0px 0px 25px rgba(255, 159, 64, 0.8);
}
