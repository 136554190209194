.terms-page {
  background-color: #f7f8fa;
  min-height: 100vh;
}

.terms-card {
  background-color: #ffffff;
  border-radius: 10px;
  border: none;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
}

h1 {
  font-size: 2.8rem;
  font-weight: bold;
  color: #343a40;
}

h3 {
  margin-top: 25px;
  font-size: 1.75rem;
  font-weight: bold;
  color: #007bff;
}

p,
ul {
  font-size: 1rem;
  color: #555;
}

p {
  line-height: 1.7;
}

ul {
  list-style-type: disc;
  padding-left: 20px;
  line-height: 1.7;
}

a {
  color: #007bff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.text-center {
  text-align: center;
}
